import {Helmet} from 'react-helmet-async';
import React from 'react';
import Slider from 'react-slick';

import segmentAnalytics from '@analytics/client';
import {reducer as marketLayoutReducer} from 'web/components/market_layout';
// import {GOODEGGS_SELECT_URL} from 'web/constants';
import {PageType} from 'web/helpers/redux_client';

import Image from '../../components/image';
import {StoreData} from './controller';

const WelcomePage: PageType<StoreData | undefined> = () => {
  const handleRedirect = ({ctaLink, ctaText}: {ctaLink: string; ctaText: string}): void => {
    const {pathname} = window.location;

    segmentAnalytics.track('ctaClicked', {
      type: 'button',
      text: ctaText,
      destination: ctaLink,
      pageUrl: pathname,
      feature: pathname.split('/').slice(-1)[0],
    });
    window.location.assign(ctaLink);
  };

  return (
    <main className="welcome-page__container">
      <Helmet>
        <title>Good Eggs - California grocery delivery of organic produce</title>
        <body className="no-margin" />
        <meta property="og:type" content="food.delivery" />
        <meta
          property="og:title"
          content="GoodEggs | Locally-Sourced | Peak-Quality | High-Integrity | Food"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:description"
          content="Radical freshness, impeccable quality, curated selections and exceptional service. Picked yesterday. Baked this morning. By people we know and trust. We go straight to the source and bring only the best right to you."
        />
        <meta property="og:site_name" content="Good Eggs" />
      </Helmet>

      <section className="welcome-page__hero-container" data-testid="welcome-page__hero">
        <div className="welcome-page__hero-content-container">
          <div className="welcome-page__hero-header-top-container">
            <Image
              className="welcome-page__hero-header-logo"
              onClick={() =>
                handleRedirect({
                  ctaLink: '/home',
                  ctaText: 'Good Eggs logo',
                })
              }
              src="/img/svg/icons/logo_green.svg"
              width={120}
              alt="Good Eggs logo"
            />
            <button
              type="button"
              className="welcome-page__button welcome-page__button--small welcome-page__button-sign-in"
              onClick={() => handleRedirect({ctaLink: '/signin', ctaText: 'sign in'})}
            >
              sign in
            </button>
          </div>
          <div className="welcome-page__hero-header-text">a radically fresh market</div>
          <div className="welcome-page__hero-tagline">
            <div>picked yesterday.</div>
            <div>delivered today.</div>
          </div>
          <div className="welcome-page__hero-shop-button-container">
            <button
              type="button"
              className="welcome-page__button welcome-page__button--large welcome-page__button--shop-local"
              onClick={() => handleRedirect({ctaLink: '/home', ctaText: 'shop local*'})}
            >
              shop local*
            </button>
            {/* <button
              type="button"
              className="welcome-page__button welcome-page__button--large welcome-page__button--shop-nationwide"
              onClick={() =>
                handleRedirect({
                  ctaLink: GOODEGGS_SELECT_URL,
                  ctaText: 'ship nationwide',
                })
              }
            >
              ship nationwide
            </button> */}
          </div>
          <div className="welcome-page__hero-annotation">
            *SF Bay Area, Los Angeles, and Orange County
          </div>
        </div>
      </section>

      <section className="welcome-page__content-container">
        <div className="welcome_page__who-we-are">
          <p>
            From California’s best, peak-seasonal produce sourced from the organic farms we know and
            trust to curated groceries and convenient meals prepped and prepared by our own Good
            Eggs Kitchen, we believe in radical freshness you can taste.
          </p>
          <p>
            This is exceptional flavor with unparalleled service.
            <span className="welcome_page__who-we-are-goodeggs"> We&apos;re Good Eggs.</span>
          </p>
        </div>

        <hr className="welcome-page__divider" data-testid="welcome-page__divider" />

        <section className="welcome-page__our-recipes-container">
          <div className="welcome-page__our-recipes-header">our recipe for good food</div>
          <div className="welcome-page__recipe-container">
            <div className="welcome-page__recipe-description-container">
              <div className="welcome-page__recipe-description-header">curation</div>
              <div className="welcome-page__recipe-description-text">
                We believe in radical freshness you can taste. Picked at peak season and hand
                selected for exceptional flavor and quality, always.
              </div>
            </div>
            <Image
              className="welcome-page__recipe_image"
              src="/img/web/welcome/recipe-curation.jpg"
              width={545}
              alt="Curation photo"
            />
          </div>

          <div className="welcome-page__recipe-container">
            <Image
              className="welcome-page__recipe_image"
              src="/img/web/welcome/recipe-convenience.jpg"
              width={545}
              alt="Convenience photo"
            />
            <div className="welcome-page__recipe-description-container">
              <div className="welcome-page__recipe-description-header">convenience</div>
              <div className="welcome-page__recipe-description-text">
                We go straight to our trusted farmers and food makers to bring you California’s
                best. The freshest produce, quality groceries, and seasonal meal solutions delivered
                right to you, right when you need them.
              </div>
            </div>
          </div>
          <div className="welcome-page__recipe-container">
            <div className="welcome-page__recipe-description-container">
              <div className="welcome-page__recipe-description-header">celebration</div>
              <div className="welcome-page__recipe-description-text">
                We celebrate really good food that inspires craft and creativity, and builds
                community.
              </div>
            </div>
            <Image
              className="welcome-page__recipe_image"
              src="/img/web/welcome/recipe-celebration.jpg"
              width={545}
              alt="Celebration photo"
            />
          </div>
          <div className="welcome-page__recipe-container">
            <Image
              className="welcome-page__recipe_image"
              src="/img/web/welcome/recipe-connection.jpg"
              width={545}
              alt="Connection photo"
            />
            <div className="welcome-page__recipe-description-container">
              <div className="welcome-page__recipe-description-header">connection</div>
              <div className="welcome-page__recipe-description-text">
                We connect through the joy of food that brings us together. Across tables. Across
                cultures. Across generations.
              </div>
            </div>
          </div>
        </section>

        <hr className="welcome-page__divider" data-testid="welcome-page__divider" />

        <section className="welcome-page__reviews-container" data-testid="welcome-page__reviews">
          <div className="welcome-page__reviews_header">love for good eggs</div>
          <Slider
            dots={false}
            infinite
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            className="welcome-page__reviews-carousel"
            prevArrow={
              <Image
                src="/img/web/welcome/carousel-prev-arrow.png"
                width={26}
                alt="Reviews previous"
                data-testid="welcome-page__reviews-carousel-prev"
              />
            }
            nextArrow={
              <Image
                src="/img/web/welcome/carousel-next-arrow.png"
                width={26}
                alt="Reviews next"
                data-testid="welcome-page__reviews-carousel-next"
              />
            }
          >
            <div className="welcome-page__reviews-carousel-slide">
              <div className="welcome-page__reviews-carousel-slide-text">
                “There’s just one word for the food from Good Eggs that successfully made its way
                into my kitchen: phenomenal!”
              </div>
              <Image
                className="welcome-page__reviews-carousel-slide-logo"
                src="/img/web/welcome/nyt_logo.png"
                width={272}
                alt="New York Times logo"
              />
            </div>
            <div className="welcome-page__reviews-carousel-slide">
              <div className="welcome-page__reviews-carousel-slide-text">
                “There’s an opportunity for Good Eggs to reimagine food and reimagine grocery
                towards a much fresher product.”
              </div>
              <Image
                className="welcome-page__reviews-carousel-slide-logo"
                src="/img/web/welcome/fast_logo.png"
                width={272}
                alt="FAST logo"
              />
            </div>
          </Slider>
        </section>

        <hr className="welcome-page__divider" data-testid="welcome-page__divider" />

        <section
          className="welcome-page__get-started-container"
          data-testid="welcome-page__get-started"
        >
          <div className="welcome-page__get-started-header">ready for radically fresh?</div>
          <div className="welcome-page__get-started-button-container">
            <button
              type="button"
              className="welcome-page__button welcome-page__button--large welcome-page__button--shop-local"
              onClick={() => handleRedirect({ctaLink: '/home', ctaText: 'shop local*'})}
            >
              shop local*
            </button>
            {/* <button
              type="button"
              className="welcome-page__button welcome-page__button--large welcome-page__button--shop-nationwide"
              onClick={() =>
                handleRedirect({
                  ctaLink: GOODEGGS_SELECT_URL,
                  ctaText: 'ship nationwide',
                })
              }
            >
              ship nationwide
            </button> */}
          </div>
        </section>
      </section>
    </main>
  );
};

WelcomePage.pageName = 'Welcome';
WelcomePage.reducer = (state, action) => {
  if (!state) throw new Error('State should always be preloaded here');
  return marketLayoutReducer(state, action);
};

export default WelcomePage;
